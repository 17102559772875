import React from 'react'
import {
  Container,
  Icon,
  Flex,
  Link as A,
  theme
} from '@hackclub/design-system'
import styled from 'styled-components'

import { Fade } from 'react-reveal'

const Services = styled(Flex)`
  flex-direction: row;
  a {
    line-height: 0;
  }
  svg {
    fill: currentColor;
    width: 100px;
    height: 100px;
    transition: 0.5s;
    &:hover {
      transform: scale(1.4);
    }
  }
  ${theme.mediaQueries.md} {
    max-width: 90vw;
  }
`

const Service = ({ href, icon, ...props }) => (
  <A
    target="_blank"
    rel="noopener"
    href={href}
    mr={2}
    mb={2}
    color="muted"
    aria-label={`Coding Sastra on ${icon}`}
    children={<Icon glyph={icon} />}
    {...props}
  />
)
Services.defaultProps = {
  align: 'center',
  mb: 3,
  wrap: true
}

const HomeSocialIcons = () => {
  return (
    <>
      <Container align="center" color="black" m={3}>
        <Fade bottom>
          <Services wrap justify="space-between">
            <Service
              href="https://www.facebook.com/codingsastra/"
              icon="facebook"
              style={{ color: '#3b5998' }}
            />
            <Service
              href="https://twitter.com/codingsastra"
              icon="twitter"
              style={{ color: '#00acee' }}
            />
            <Service
              href="https://www.youtube.com/c/codingsastra"
              icon="youtube"
              style={{ color: '#c4302b' }}
            />
            <Service
              href="https://codingsastra.slack.com/"
              icon="slack"
              style={{ color: '#CF0E5B' }}
            ></Service>
          </Services>
        </Fade>
      </Container>
    </>
  )
}

export default HomeSocialIcons
