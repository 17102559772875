import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import partnerlogo from '../assets/imgs/partner.jpg'

import { graphql, useStaticQuery } from 'gatsby'

import { Container, Link as A } from '@hackclub/design-system'
import styled from 'styled-components'

const Partners = () => {
  var settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const data = useStaticQuery(graphql`
    query allPartners {
      allContentfulPartner {
        nodes {
          id
          url
          logo {
            file {
              url
            }
          }
        }
      }
    }
  `)

  const DIV = styled.div`
    width: auto;
    height: auto;
    outline: none;
    cursor: pointer;
    img {
      outline: none;
    }
    @media (max-width: 426px) {
      margin-bottom: 20px;
      padding-left: 105px;
    }
    @media (max-width: 321px) {
      padding-left: 55px;
    }
  `

  const Partners = data.allContentfulPartner.nodes

  return (
    <>
      <Container align="center" color="black" m={3}>
        <Slider {...settings}>
          {Partners.map(partner => {
            return (
              <DIV key={partner.id} className="partners">
                <A
                  href={partner ? partner.url : '/'}
                  target={partner.url ? '_blank' : ''}
                >
                  {' '}
                  <div
                    style={{
                      height: '12rem',
                      width: '12rem',
                      borderRadius: '2%',
                      backgroundImage: `url(${
                        partner.logo ? partner.logo.file.url : partnerlogo
                      })`,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat'
                    }}
                  ></div>
                </A>
              </DIV>
            )
          })}
        </Slider>
      </Container>
    </>
  )
}

export default Partners
