import React from 'react'
import styled from 'styled-components'
import { Box, Flex, Avatar, Icon, Text, theme } from '@hackclub/design-system'
import Carousel from 'nuka-carousel'
import { Subhline } from 'components/Content'
import Sheet from 'components/Sheet'
import Stat from 'components/Stat'
import { graphql, useStaticQuery } from 'gatsby'
import user from '../assets/imgs/team.jpg'

const Base = styled(Box.section).attrs({
  pt: [2, 2, 2],
  pb: [4, 5, 6]
})``

const Main = styled(Sheet).attrs({
  bg: '#252429',
  color: 'smoke',
  p: 0,
  mt: 2
})`
  border-radius: 0;
  position: relative;
  overflow: hidden;
  ${theme.mediaQueries.md} {
    border-radius: ${theme.radii[2]};
  }
  ul {
    cursor: grab !important;
  }
`

const Details = styled(Box).attrs({ mt: [2, 0], px: [3, 4], pb: [4, 5] })`
  ${theme.mediaQueries.md} {
    display: grid;
    grid-template-columns: 128px 1fr;
    grid-gap: ${theme.space[5]}px;
    align-items: start;
  }
`
const Quote = styled(Text).attrs({ fontSize: [3, 4], color: 'muted' })`
  text-indent: -0.375em;
`
const DetailStats = styled(Flex).attrs({
  flexDirection: ['row', null, 'column'],
  justify: ['start', null, 'end']
})`
  p {
    color: ${theme.colors.muted};
  }
`
DetailStats.Item = props => (
  <Stat
    align={['left', null, 'right']}
    fontSize={6}
    width={1}
    mt={0}
    mb={3}
    px={0}
    {...props}
  />
)

const Event = ({ name, feedback, key, picture }) => [
  <Box
    px={[3, 4]}
    mt={[3, 4]}
    pl={[null, null, 128 + theme.space[5] + theme.space[4]]}
    key={key}
  >
    <Subhline color="white" children={name} />
  </Box>,
  <Details>
    <DetailStats>
      <Avatar src={picture} size={150} mt={5} ml={3} />
    </DetailStats>
    <Box ml={3}>
      <Quote>“{feedback.feedback}”</Quote>
    </Box>
  </Details>
]

const HomeTestimonials = () => {
  const data = useStaticQuery(graphql`
    query allTestimoinal {
      allContentfulTestimonial {
        nodes {
          id
          name
          picture {
            file {
              url
            }
          }
          feedback {
            feedback
          }
        }
      }
    }
  `)
  let events = data.allContentfulTestimonial.nodes
  return (
    <Base>
      <Main maxWidth={60}>
        <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround
          enableKeyboardControls
          renderCenterLeftControls={({ previousSlide }) => (
            <Icon
              glyph="view-back"
              color="smoke"
              size={48}
              pb={3}
              onClick={previousSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <Icon
              glyph="view-forward"
              color="smoke"
              size={48}
              pb={3}
              pr={3}
              onClick={nextSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
        >
          {events.map(event => {
            return (
              <Event
                key={event.id}
                {...event}
                name={event.name}
                picture={event.picture ? event.picture.file.url : user}
              />
            )
          })}
        </Carousel>
      </Main>
    </Base>
  )
}

export default HomeTestimonials
