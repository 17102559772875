import React from 'react'
import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Link as A,
  Text
} from '@hackclub/design-system'
import Link from 'components/Link'
import Layout from 'components/Layout'
import Nav from 'components/Nav'
import Photo from 'components/Photo'
import {
  Headline,
  Highlight,
  FeatureLink,
  MapBox,
  TextFeature,
  Slider,
  PhotoFeature,
  BankFeature,
  AboutCols,
  Steps,
  HourFeatures,
  StepOne,
  StepTwo,
  StepThree,
  HomeSectionLead,
  SectionEyebrow,
  SectionHeadline,
  contentContainer
} from 'components/Content'
import Footer from 'components/Footer'
import codeImg from '../assets/imgs/code.jpg'
import HomeTestimonials from '../components/Testimonials'
import Carousel from 'nuka-carousel'
import { Flip, Fade, Slide, Zoom } from 'react-reveal'
import { graphql } from 'gatsby'
import bg from '../assets/imgs/bg.jpg'
import slider1 from '../assets/imgs/slider1.jpg'
import slider2 from '../assets/imgs/slider2.jpg'
import banner1 from '../assets/imgs/banner3.jpg'
import banner2 from '../assets/imgs/students.jpg'
import Social from '../components/Socialicons'
import Placement from '../components/Placement/Placement'
import HomeSocialIcons from '../components/HomeSocilaIcons'
import SubScribe from '../components/Subscribe'
import Partners from '../components/Partners'

const Module = ({ icon, name, body, color = 'white', ...props }) => (
  <Flex flexDirection="column" color={color} {...props}>
    {icon && (
      <Icon
        size={64}
        mb={2}
        glyph={icon}
        color={props.iconColor || color}
        style={{ flexShrink: 0 }}
      />
    )}
    <Box>
      <Heading.h3 mb={1} fontSize={5} children={name} />
      <Text fontSize={4} style={{ lineHeight: '1.375' }} children={body} />
    </Box>
  </Flex>
)

A.link = A.withComponent(Link)

const title = 'Home - Coding Sastra'

export default ({ data }) => {
  let images = data.allContentfulSlider.nodes

  let Programs = data.allContentfulProgram.nodes

  return (
    <Layout
      title={title}
      desc={data.site.siteMetadata.description}
      keywords={data.site.siteMetadata.keywords}
      path="/"
    >
      <Nav color="slate" fixed />
      <Box mt={[44, 55]} p={3} bg="snow">
        <Carousel
          autoplay
          autoplayInterval={5000}
          wrapAround
          enableKeyboardControls
          renderCenterLeftControls={({ previousSlide }) => (
            <Icon
              glyph="view-back"
              color="smoke"
              size={48}
              pb={3}
              onClick={previousSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <Icon
              glyph="view-forward"
              color="smoke"
              size={48}
              pb={3}
              pr={3}
              onClick={nextSlide}
              style={{ cursor: 'pointer' }}
            />
          )}
        >
          {images.map((img) => {
            return img.slides.map((slide) => {
              return (
                <div key={slide.id}>
                  <Slider
                    style={{ paddingTop: 50 }}
                    src={slide.image ? slide.image.file.url : bg}
                    py={[3, 5, 6]}
                    color="white"
                    align={['left', 'center']}
                  >
                    <Container
                      width={1}
                      maxWidth={70}
                      px={3}
                      mt={[5, 6]}
                      mb={[4, 5]}
                    >
                      <Flip top>
                        <Headline
                          maxWidth={58}
                          mx="auto"
                          fontSize={[6, 7, 8]}
                          mb={1}
                        >
                          {slide ? slide.title : ''}
                        </Headline>
                      </Flip>
                      <Fade bottom>
                        <HomeSectionLead fontSize={[3, 4]} mx="auto" mb={2}>
                          {slide.description
                            ? slide.description.description
                            : ''}
                        </HomeSectionLead>
                      </Fade>
                    </Container>
                  </Slider>
                </div>
              )
            })
          })}
        </Carousel>
      </Box>
      <Social />
      <Box bg="#ce293c" pt={2} pb={1} color="white">
        <marquee hspace="10px" scrolldelay="61">
        Call or Whatsapp us at +91 9951013134 to know more about training options we have currently.
        </marquee>
      </Box>
      <Box bg="white" py={[5, 6]}>
        <Container color="black" px={3}>
          <SectionEyebrow>Our Programs</SectionEyebrow>
          <SectionHeadline>
            Programs build / extend your careers
          </SectionHeadline>
          <HomeSectionLead>
            Coding&nbsp;Sastra{' '}
            <Highlight>
              conducting several workshops on latest technologies in our
              locations and colleges
            </Highlight>{' '}
            near you. Hybrid training on latest technologies from convenience of
            your home without loosing classroom experience for practical &
            project session in classroom near you{' '}
          </HomeSectionLead>
          <HourFeatures>
            {Programs.map((program) => {
              return (
                <section key={program.id}>
                  <FeatureLink to={`/program/${program.code}/`} color="white">
                    <PhotoFeature
                      src={program.picture ? program.picture.file.url : codeImg}
                    >
                      <Text>{program.title}</Text>
                    </PhotoFeature>
                  </FeatureLink>
                </section>
              )
            })}
          </HourFeatures>
        </Container>
      </Box>
      <Flex flexDirection="column" bg="snow" py={[5, 6]}>
        <Container {...contentContainer}>
          <SectionEyebrow>OUR MISSION</SectionEyebrow>
          <SectionHeadline>
            Ignites passion to &nbsp;
            <Text.span color="teal.6">learn and make you successful.</Text.span>
            ..
          </SectionHeadline>
          <HomeSectionLead>
            Coding Sastra’s mission is to transform lives by teaching people of
            all backgrounds the technical and interpersonal skills used in
            software development so they can thrive in the IT industry. The
            vision for our company is to build a community of confident and
            passionate people with latest technologies to have a great career
            here.
          </HomeSectionLead>
          <AboutCols cols="3fr 2fr">
            <PhotoFeature src={slider1} inverted>
              <Module
                name="Believe in your passion, it drives your career."
                color="white"
              />
              <FeatureLink to="/about" color="white">
                About us &raquo;
              </FeatureLink>
            </PhotoFeature>
            <TextFeature>
              <Text>
                At&nbsp;<Text.span color="info">Coding Sastra, </Text.span> we
                understand that it is very common even for technical experts to
                face hurdles during the course of their job and therefore we
                provide on-the-job support services to all our current students
                and our alumni.
              </Text>
              <FeatureLink to="/corporate" color="info">
                Learn More &raquo;
              </FeatureLink>
            </TextFeature>
          </AboutCols>
          <AboutCols>
            <PhotoFeature src={slider2} inverted>
              <Text>
                We Providing unparalleled opportunities for{' '}
                <Text.span color="cyan.5">personal growth.</Text.span>
              </Text>
              <FeatureLink to="/careers" color="white">
                Learn more &raquo;
              </FeatureLink>
            </PhotoFeature>
            <BankFeature>
              <Module
                name="Our domain expertise lies in"
                color="white"
                body="- Web Development "
              />
              <Text color="white" fontSize={4}>
                - Mobile App Development
              </Text>
              <Text color="white" fontSize={4}>
                - Data Science
              </Text>
              <Text color="white" fontSize={4}>
                - Cloud Computing
              </Text>
              <Text color="white" fontSize={4}>
                - IoT – Internet of Things
              </Text>
              <FeatureLink to="/about/" color="white">
                Learn more &raquo;
              </FeatureLink>
            </BankFeature>
          </AboutCols>
        </Container>
      </Flex>
      <Box bg="white" color="black" py={[5, 6]}>
        <Container px={3}>
          <SectionEyebrow>Philosophy</SectionEyebrow>
          <SectionHeadline>
            Coding Sastra born,{' '}
            <Text.span color="warning">for the students</Text.span>.
          </SectionHeadline>
          <HomeSectionLead>
            Coding Sastra’s mission is to transform lives by teaching people of
            all backgrounds the technical and interpersonal skills used in
            software development so they can thrive in the IT industry. The
            vision for our company is to build a community of confident and
            passionate people with latest technologies to have a great career
            here.
          </HomeSectionLead>
          <Zoom>
            <AboutCols cols="1fr 1fr">
              <Photo src={banner1} aria-label="img1" />
              <Photo src={banner2} aria-label="img2" />
            </AboutCols>
          </Zoom>
        </Container>
      </Box>
      <Box bg="snow" color="black" pt={[2, 2]}>
        <Container px={3}>
          <SectionEyebrow>Testimonials</SectionEyebrow>
          <HomeTestimonials />
        </Container>
      </Box>
      <Box bg="white" color="black" pt={[5, 6]} pb={3}>
        <Container px={3}>
          <SectionEyebrow>Methodology</SectionEyebrow>
          <SectionHeadline>
            Coding Sastra <Text.span color="primary">features</Text.span>
          </SectionHeadline>
          <HomeSectionLead>
            Learner friendly environment. We’ll help from there!
          </HomeSectionLead>
          <Steps color="white">
            <Slide left>
              <StepOne>
                <A href="https://docs.google.com/forms/d/e/1FAIpQLSds9Lhf8gwCJslxHRJ8ert0IWshl09tQHHX2qHFRsGi0tc7iQ/viewform">
                  <Module
                    name="Immersive training by experts"
                    body="With the team of experts, we guarantee the friendly atmosphere for freshers to learn without fear with our one-on-one attention."
                  />
                </A>
              </StepOne>
            </Slide>
            <StepTwo>
              <Module
                name="Hands-On from day one"
                body="Our trainings are completely project based with full hands-on from day one to give you fully immersive and interesting."
              />
            </StepTwo>
            <Slide right>
              <StepThree>
                <Module
                  name="Powerful Learning Tools"
                  body="Coding Sastra provide the best environment for learning via a friendly location with easy access and use tools for easy communications."
                />
              </StepThree>
            </Slide>
          </Steps>
        </Container>
      </Box>
      <Box bg="snow" color="black" pt={[2, 2]}>
        <Container px={3}>
          <SectionEyebrow align="center" mb={3} mt={3}>
            Placements
          </SectionEyebrow>
          <Placement />
        </Container>
      </Box>
      <Box bg="white" color="black" pt={[2, 2]}>
        <Container px={3}>
          <SectionEyebrow align="center" mb={3} mt={3}>
            Follow Us
          </SectionEyebrow>
          <HomeSocialIcons />
        </Container>
      </Box>
      <Box bg="snow" color="black" pt={[2, 2]}>
        <Container px={3}>
          <SectionEyebrow align="center" mb={3} mt={3}>
            Subscribe to Our Newsletter
          </SectionEyebrow>
          <SubScribe />
        </Container>
      </Box>
      <Box bg="white" color="black" pt={[2, 2]}>
        <Container px={3}>
          <SectionEyebrow align="center" mb={3} mt={3}>
            Partners
          </SectionEyebrow>
          <Partners />
        </Container>
      </Box>
      <Box p={[3, 4, 5]}>
        <MapBox
          py={[3, 5, 6]}
          bg="darker"
          src={codeImg}
          aria-label="Students at a coding event"
          align={['left', 'center']}
          color="white"
        >
          <Container
            width={1}
            maxWidth={72}
            px={2}
            mt={[4, 5, 6]}
            mb={[3, 4, 5]}
          >
            <Headline>Start learning at Coding Sastra.</Headline>
            <Fade bottom>
              <Text fontSize={[3, 5]} my={[1, 2]}>
                Immersive training.
              </Text>
              <Text fontSize={[3, 5]} my={[1, 2]}>
                Hands-On from day one.
              </Text>
              <Text fontSize={[3, 5]} my={[1, 2]}>
                Powerful Learning Tools.
              </Text>
            </Fade>
          </Container>
        </MapBox>
      </Box>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query allContentfulHomePageBanner {
    allContentfulSlider {
      nodes {
        id
        slides {
          id
          title
          description {
            description
          }
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulProgram(sort: { fields: order }) {
      nodes {
        id
        title
        code
        picture {
          file {
            url
          }
        }
      }
    }
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
