import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import team from '../../assets/imgs/team.jpg'

import { graphql, useStaticQuery } from 'gatsby'

import { Container, Text } from '@hackclub/design-system'
import styled from 'styled-components'

const Placement = () => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    dots: true,
    centerPadding: '-120px',
    slidesToShow: 3,
    rows: 2,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesPerRow: 2,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  }

  const data = useStaticQuery(graphql`
    query allPlacements {
      allContentfulPlacement {
        nodes {
          id
          studentName
          studentPicture {
            file {
              url
            }
          }
          position
          company
        }
      }
    }
  `)

  const DIV = styled.div`
    width: auto;
    height: auto;
    outline: none;
    position: relative;
    text-align: center;
    margin-bottom: 40px;
    cursor: pointer;
    > p {
      margin: 5px 30px 5px 0px;
    }
    @media (max-width: 769px) {
      > p {
        margin-right: 50px;
      }
    }
    @media (max-width: 426px) {
      margin-bottom: 20px;
      padding-left: 105px;
      > p {
        margin-right: 25px;
      }
    }
    @media (max-width: 321px) {
      padding-left: 55px;
    }
  `

  const Placements = data.allContentfulPlacement.nodes
  return (
    <>
      <Container align="center" color="black" m={3}>
        <Slider {...settings}>
          {Placements.map(student => {
            return (
              <DIV key={student.id} className="placement">
                <img
                  src={
                    student.studentPicture
                      ? student.studentPicture.file.url
                      : team
                  }
                  alt="..."
                  style={{
                    height: '11rem',
                    width: '11rem',
                    borderRadius: '50%'
                  }}
                />

                <Text>
                  <strong> {student.studentName}</strong>
                </Text>
              </DIV>
            )
          })}
        </Slider>
      </Container>
    </>
  )
}

export default Placement
